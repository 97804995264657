import { render, staticRenderFns } from "./Icon_template_a02f3f27-5c91-4d25-826c-bde84a0fc969.vue?vue&type=template&id=616065d5&"
import script from "./Icon_template_a02f3f27-5c91-4d25-826c-bde84a0fc969.vue?vue&type=script&lang=js&"
export * from "./Icon_template_a02f3f27-5c91-4d25-826c-bde84a0fc969.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports