<template>
  <svg
    id="1fcc74a0-65b3-4866-83ae-74e7f273982d"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37">
    <path
      d="M7.37,0H29.63A7.38,7.38,0,0,1,37,7.38V29.63A7.37,7.37,0,0,1,29.63,37H7.38A7.37,7.37,0,0,1,0,29.63V7.37A7.37,7.37,0,0,1,7.37,0Z"
      class="icon-wrapper__template"
      :fill="color" />
    <path
      d="M31.7,29.6H28.86V26.91a3.1,3.1,0,0,0,1.48-5.51,2.22,2.22,0,1,0-3.84,0A3.1,3.1,0,0,0,28,26.91v.64L26.84,27a.44.44,0,
        1,0-.4.79h0l1.53.76V29.6H24.43V16.31a.44.44,0,0,0-.2-.37l-5.32-3.55a.44.44,0,0,0-.49,0L13.1,
        15.94a.44.44,0,0,0-.2.37V29.6H9.36V26.91a3.1,3.1,0,0,0,1.48-5.51A2.22,2.22,0,1,0,7,21.4a3.1
        ,3.1,0,0,0,1.48,5.51v.64L7.34,27a.44.44,0,1,0-.4.79h0l1.53.76V29.6H5.37a.44.44,0,1,0,0
        ,.89H31.7a.44.44,0,0,0,0-.89Zm-5.5-5.76a2.22,2.22,0,0,1,1.14-1.94.44.44,0,0,0
        ,.17-.6l-.07-.09a1.33,1.33,0,1,1,1.93,0,.44.44,0,0,0,0,.63l.09.07A2.22,2.22,0,0,1,28.86,26V24.28a.44.44,
        0,0,0-.89,0V26a2.22,2.22,0,0,1-1.77-2.17Zm-19.5,0A2.22,2.22,0,0,1,7.84,21.9.44.44,0,0,0,8,
        21.3l-.07-.09a1.33,1.33,0,1,1,1.93,0,.44.44,0,0,0,0,.63l.09.07A2.22,2.22,0,0,1,9.36
        ,26V24.28a.44.44,0,1,0-.89,0V26A2.22,2.22,0,0,1,6.7,23.84Zm8.92,5.76V25.17h2.22V29.6Zm3.1
        ,0V24.73a.44.44,0,0,0-.44-.44h-3.1a.44.44,0,0,0-.44.44V29.6h-.95V16.54l4.88-3.25,4.88,3.25V29.6Z"
      transform="translate(-0.03 0)"
      fill="#fff" />
    <path
      d="M22.15,19.1h-7a.44.44,0,0,0-.44.44v2.22a.44.44,0,0,0,.44.44h7a.44.44,0,0,0,.44-.44V19.55A.44.44,0,0,0,22.15,19.1Zm-.44,2.22h-6.1V20h6.1Z"
      transform="translate(-0.03 0)"
      fill="#fff" />
    <path
      d="M22,24.32H20a.44.44,0,0,0-.44.44V27a.44.44,0,0,0,.44.44h2a.44.44,0,0,0,.44-.44V24.76A.44.44,0,0,0,22,24.32Zm-.44,2.22H20.44V25.21H21.6Z"
      transform="translate(-0.03 0)"
      fill="#fff" />
    <path
      d="M9.13,11h6.65a1.55,1.55,0,0,0,0-3.1H14.34a2.66,2.66,0,0,0-5.25,0,1.55,1.55,0,0,0,0,3.1ZM8.66,9a.66.66,0,0,1,.47-.2H9.5a.44.44,0,0,0,.44-.44,1.77,1.77,0,0,1,3.55
              ,0,.44.44,0,0,0,.44.44h1.85a.66.66,0,1,1,0,1.33H9.13A.66.66,0,0,1,8.66,9Z"
      transform="translate(-0.03 0)"
      fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>